import { Flex, FlexGap, useMatchBreakpoints } from '@pancakeswap/uikit'
import useTheme from 'hooks/useTheme'
import React from 'react'
import { styled } from 'styled-components'

const Container = styled(Flex)`
  background: #330c27;
  padding: 0 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0 40px 70px;
  }
`

const StyledText = styled.div`
  font-size: 20px;
  padding-bottom: 16px;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 40px;
    padding-bottom: 80px;
  }

  font-weight: 600;
  text-align: center;
`

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;

  ${({ theme }) => theme.mediaQueries.md} {
    gap: 40px;
    grid-template-columns: 1fr 1fr;
  }
`

const ImageContainer = styled.div`
  position: absolute;
  bottom: 0px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: 100%;
  left: 0;

  ${({ theme }) => theme.mediaQueries.sm} {
    bottom: 0;
  }
`

const StyledCard = styled.div`
  position: relative;
  border-radius: 12px;
  background: linear-gradient(90deg, #571b43 0%, #401138 100%);
  padding: 19px 24px;
  max-width: 329px;
  height: 214px;

  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 498px;
    padding: 38px 57px 38px 47px;
    height: 392px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    border-radius: 24px;
  }

  > .title {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 8px;

    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 32px;
      margin-bottom: 16px;
    }
  }

  > .description {
    font-size: 12px;
    color: #c695b5;
    line-height: 1.5;

    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 16px;
    }
  }

  &:nth-child(4) > ${ImageContainer} {
    bottom: 0px;

    ${({ theme }) => theme.mediaQueries.lg} {
      bottom: 0px;
    }
  }
`

interface CardProps {
  title: string
  description: string
  Image: React.ReactElement
}

const Card = ({ title, description, Image }: CardProps) => {
  return (
    <StyledCard>
      <div className="title">{title}</div>
      <div className="description">{description}</div>
      <ImageContainer>{Image}</ImageContainer>
    </StyledCard>
  )
}

export const DiscoverEcosystem = () => {
  const { theme } = useTheme()
  const { isMobile } = useMatchBreakpoints()

  return (
    <Container alignItems="center" flexDirection="column">
      <Flex flexDirection="column" alignItems="center" style={{ gap: '20px' }}>
        <FlexGap flexDirection="column" alignItems="center" gap="12px">
          <img
            src="/images/home/pankoSmallLogo.svg"
            alt="muscle"
            width={isMobile ? 20 : 40}
            height="auto"
            style={{ zIndex: 1 }}
          />

          <StyledText>
            Discover the <span style={{ color: theme.colors.secondary }}>Ecosystem</span>
          </StyledText>
        </FlexGap>
        <CardContainer>
          <Card
            title="Swap/Trade"
            description="Trade assets on Taiko seemlessly through secured routes on Panko"
            Image={<img src="/images/home/panko/1.png" alt="discovery-swap" width={isMobile ? 258 : 495} />}
          />
          <Card
            title="Liquidity Pools"
            Image={<img src="/images/home/panko/2.png" alt="discovery-liquidity" width={isMobile ? 329 : 495} />}
            description="Provide liquidity and receive LP token representing claims to trading fees on Panko"
          />
          <Card
            title="Yield Farming"
            description="Lock your LP token in smart contract to prevent Impermanant Loss and earn $PANKO Rewards"
            Image={<img src="/images/home/panko/3.png" alt="discovery-yield" width={isMobile ? 250 : 495} />}
          />
          <Card
            title="Permissionless Pools, Gauges, and Incentives"
            description="Introducing veToken system. Voters can vote on token emissions and receive incentives and fees generated, allowing them to build liquidity at a low cost."
            Image={<img src="/images/home/panko/4.png" alt="discovery-permission" width={isMobile ? 178 : 495} />}
          />
        </CardContainer>
      </Flex>
    </Container>
  )
}
